// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "GM 👋",
  title2: "Charles",
  logo_name: "charlie.b()",
  nickname: "charlie",
  first_name: 'Charlie',
  full_name: "Charles Buckley",
  subTitle: "Front End Web/Blockchain Developer. Forever learning.",
  resumeLink:
    "https://www.docdroid.net/Mf4f0em/2022-resume-pdf",
  mail: "mailto:charlesbuckley122@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/0xagf0x",
  linkedin: "https://www.linkedin.com/in/charles-buckley-20447950/",
  gmail: "charlesbuckley122@gmail.com",
  twitter: "https://twitter.com/chuck__dynamite",
};

const skills = {
  data: [
    {
      title: "Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developed highly interactive Front End / UI for web and mobile applications",
        "⚡ Experience in web development with JavaScript frameworks",
        "⚡ Experience with integrating 3rd party APIs",
        "⚡ Advanced problem-solving skills and the ability to optimize data for the best possible outcome"
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#0db7ed",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Blockchain Development",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Built and Deployed custom Smart Contracts on Ethereum testnet",
        "⚡ Experience interacting with the EVM and writing high-quality, well-tested Solidity",
        "⚡ Advanced analytical and problem-solving skills",
      ],
      softwareSkills: [
        {
          skillName: "Solidity",
          fontAwesomeClassname: "simple-icons:solidity",
          style: {
            color: "#949494",
          },
        },
        {
          skillName: "Etherum",
          fontAwesomeClassname: "simple-icons:ethereum",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Semantic UI",
          fontAwesomeClassname: "simple-icons:semanticuireact",
          style: {
            color: "#24a196",
          },
        },
        {
          skillName: "Material UI",
          fontAwesomeClassname: "simple-icons:material-ui",
          style: {
            color: "#2f67ee",
          },
        },
        {
          skillName: "Mocha",
          fontAwesomeClassname: "simple-icons:mocha",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextjavascript",
          style: {
            color: "#61DAFB",
          },
        },
      ],
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Check out my work history",
  description: "Check out my work history",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Frontend Developer",
          company: "AliensAmongUs.io",
          company_url: "/",
          logo_path: "aau.png",
          duration: "Nov 2021 - Current",
          location: "Remote",
          description1:
            "- Created custom web dapp using React, NextJS, Web3JS and Bootstrap. ",
          description2:
            "- Worked on the development and implementation of new blockchain technologies and completed professional market research.",
          description3:
            "-  Supported CEO with daily maintenance and operations.",
          description4: "Utilized Solidity to help create custom SmartContracts.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Front End Developer",
          company: "Mobelux",
          company_url: "https://mobelux.com/",
          logo_path: "mobelux.jpeg",
          duration: "Dec 2019 - Current",
          location: "Remote",
          description1:
            "- Translated design team's wireframes and mockups into responsive, interactive features, using Django, React, and JavaScript.",
          description2:
            "- Implemented websites, web applications, and custom template pages from concept through deployment.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "UI/UX Developer",
          company: "Fusion / Allianz Global",
          company_url: "https://www.allianztravelinsurance.com/",
          logo_path: "fusion.jpeg",
          duration: "May 2019 - Dec 2019 (Contract)",
          location: "Richmond, VA",
          description1: "- Drove the development of key UI/UX experiences which incorporate high-level interaction, navigation, and organization design using JavaScript, CSS, and other Front End technologies.",
          description2: "- Oversaw and provided thought leadership in the assessment of ad units for engagement, usability and visual design.",
          description3: "- Interacted with client representatives and work collaboratively with similar designers/developers on their teams to promote the best design for the clients brand",
          description4: "- Acted as a subject matter expert for all front- end development needs for Fusion including design, development, and integration strategy.",
          description5: "- Collaborated with Optimization Strategy team to develop new template ideas to test.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Front End Developer",
          company: "Maxx Potential",
          company_url: "https://maxxpotential.com/",
          logo_path: "maxx_potential.png",
          duration: "Dec 2017 - May 2019",
          location: "Richmond, VA",
          description1: "- Built client websites using WordPress while modifying existing code as needed.",
          description2: "- Worked hand in hand with the QA Department on a customized testing plan and developed custom themes.",
          description3: "- Helped shape IT strategic vision and drive key departmental objectives.",
          description4: "- Monitored website traffic and performance analytics.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Front End Developer",
          company: "Denver Disco (Freelance)",
          company_url: "https://www.facebook.com/DenverDisco",
          logo_path: "denver_disco.jpeg",
          duration: "Aug 2017 - Nov 2017",
          location: "Remote",
          description1: "- Built custom website using HTML, CSS, JavaScript, and PHP.",
          description2: "- Carried out QA tests to discover errors and optimize usability.",
          description3: "- Optimized application for maximum speed and scalability and planned site/ project design by clarifying goals.",
          description4: "- Tested front- end code in multiple browsers to ensure cross - browser compatibility.",
          description5: "- Participated in projects in all stages of the product life cycle and leveraged responsive web frameworks to consistently complete product deliverables ahead of schedule.",
          color: "rgb(127, 141, 170)",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Random assortment of projects",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "portrait.jpeg",
    description:
      "Lets chat",
  },
  blogSection: {
    title: "Blogs/Twitter",
    subtitle:
      "I write on Twitter. Mostly about tech and crypto",
    link: "https://twitter.com/0xagf0x",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
     {
      name: "Dinkers Club",
      url: "https://dinkersclub.com/",
      description:
        "A custom Shopify site I helped build for a client. I was responsible for the front-end development and design.",
      languages: [
        {
          name: "Shopify",
          iconifyClass: "logos:shopify",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "My Blog",
      url: "https://nextjs-blog-cbuckley88.vercel.app/",
      description:
        "A basic blog built with NextJS and GraphQL",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos:nextjs-icon",
        },
        {
          name: "GraphQL",
          iconifyClass: "mdi:graphql",
        },
        {
          name: "Vercel",
          iconifyClass: "logos-vercel",
        },
      ],
    },
    {
      name: "Todo app",
      url: "https://svelte-todo-hazel.vercel.app/",
      description:
        "A basic to-do built with Svelte and Tailwind",
      languages: [
        {
          name: "Svelte",
          iconifyClass: "vscode-icons:file-type-svelte",
        },
        {
          name: "Tailwind",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
        {
          name: "Vercel",
          iconifyClass: "logos-vercel",
        },
      ],
    },
    {
      name: "Web3 Trello",
      url: "https://web3-trello.vercel.app/",
      description:
        "A clone of Trello, built on the Ethereum blockchain (Goerli network). Built using Solidity, React, Ethers.JS and Hardhat",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
     {
      name: "PNS (Poot Name Service)",
      url: "https://polygon-domain-services-lac.vercel.app/",
      description:
        "Create your own DNS on the Layer-2 Polygon blockchain (Mumbai testnet)",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
     {
      name: "Tailwind Landing Page",
      url: "https://0xagf0x.github.io/tailwind-landing-page/",
      description:
        "A basic landing page built with Tailwind CSS",
      languages: [
         {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "Tailwind",
          iconifyClass: "logos:tailwindcss-icon",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
    {
      name: "Basic Weather App",
      url: "https://weather-juexlrglm-cbuckley88.vercel.app/",
      description:
        "A basic weather app built with React and OpenWeather API",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Vercel",
          iconifyClass: "logos-vercel",
        },
      ],
    },
    {
      name: "NFT Battle Game",
      url: "https://github.com/0xagf0x/battle_game.eth",
      description:
        "A turn-based NFT game where users can team up to battle a boss",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
    {
      name: "NFT Minting for OpenSea",
      url: "https://github.com/0xagf0x/nft_collection.eth",
      description:
        "Create a randomly generated NFT, viewable on OpenSea",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
     {
      name: "Trvia Game",
      url: "https://trivia-game-lemon.vercel.app/",
      description:
        "A simple trivia game built with React",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "DeFi Staking App",
      url: "https://github.com/0xagf0x/defi-staking-app",
      description:
        "A basic Tether(USDT) minter + staking application",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Bootstrap",
          iconifyClass: 'logos-bootstrap',
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
    {
      name: "Kickstarter.eth",
      url: "https://github.com/0xagf0x/kickstart.eth",
      description:
        "A crowd funding app built for the Ethereum blockchain",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name:"Semantic UI",
          iconifyClass: 'logos:semantic-ui',
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
    {
      name: "Binance market data collector",
      url: "https://github.com/0xagf0x/binance-api-tool",
      description:
        "A tool used for retrieving crypto market data",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
    {
      name: "Lottery.eth",
      url: "https://github.com/0xagf0x/react-lottery.eth",
      description:
        "A very lightweight lottery app built using Solidity + Web3js",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
    {
      name: "The Press Hook",
      url: "https://www.thepresshook.com/",
      description:
        "A custom Django/Wagtail application I helped build with Mobleux, used by journalists and publicists to connect with emerging brands.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Django",
          iconifyClass: "logos-django",
        },
      ],
    },
    {
      name: "Covid-19 Tracker",
      url: "https://basic-covid-19-tracking-app.herokuapp.com/",
      description:
        "A Covid-19 tracking app built with React and Material-UI",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku",
        },
      ],
    },
    {
      name: "Image Finder",
      url: "https://basic-pixabay-img-search.herokuapp.com/",
      description:
        "An image search results app built with React Pixabay API",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku",
        },
      ],
    },
    {
      name: "Drum Machine",
      url: "/drum_machine/index.html",
      description:
        "Fun key-based drum machine built using JavaScript",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "NY Times Fetcher",
      url: "/ny_times/index.html",
      description:
        "Fetches news articles using NY Times API",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "MS Paint Lite",
      url: "/painter/index.html",
      description:
        "A lightweight MS Paint clone. Create your own work of art!",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "Calculator Clone",
      url: "https://github.com/0xagf0x/react-iOS-calculator",
      description:
        "This is a simple calculator app",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Github",
          iconifyClass: "logos-github",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
